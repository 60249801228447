export const extractNavBaseUrl = (url: string | undefined) => {
  if (!url) {
    return '';
  }

  const parts = url.split('/').filter(Boolean);

  return `/${parts[0]}${(parts[1] && `/${parts[1]}`) || ''}`;
};

// Represents the `tag` field of the navs
export enum NavigationSection {
  MyLearning = 'my_learning',
  MyDevelopment = 'my_development',
  Community = 'community',
  Coaching = 'coaching',
  MyTeam = 'my_team',
  Manage = 'manage',
  Reporting = 'reporting',
  Settings = 'settings',
}
