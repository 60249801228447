import {
  PrimaryNavLink,
  PrimaryNavLinkGroup,
  SettingsMenu,
} from '@/json_schema_types/sidebar_navigation';
import { extractNavBaseUrl, NavigationSection } from '@/helpers/navigationHelpers';
import { ref } from 'vue';

interface IUseActiveNavProps {
  primaryNavItems: PrimaryNavLinkGroup[];
  settingsNavItems?: SettingsMenu;
}

const generateNavKeys = (navs: {
  primaryNavItems: PrimaryNavLinkGroup[];
  settingsNavItems?: SettingsMenu;
}) => {
  const { primaryNavItems, settingsNavItems } = navs;
  if (!primaryNavItems?.length) return {};

  const navMap: Record<string, string> = {};

  // Recursive function for link children
  const processLinks = (links: PrimaryNavLink[], tag?: string) => {
    links.forEach((link) => {
      const linkTag = tag || link.tag;

      // If no tag was supplied at all, we skip since the tag is what determines which nav is active
      if (!linkTag) return;
      if (link.url) {
        navMap[link.url] = linkTag;
        return;
      }
      if (link.children) {
        link.children.forEach((childGroup) => processLinks(childGroup.links, linkTag));
      }
    });
  };

  // Primary navs
  primaryNavItems.forEach((navGroup) => processLinks(navGroup.links));

  // Settings navs
  if (settingsNavItems?.children) {
    settingsNavItems.children.forEach((settingsNav) => {
      if (settingsNav.url && settingsNavItems.tag) {
        navMap[settingsNav.url] = settingsNavItems.tag;
      }
    });
  }
  return navMap;
};

export default function useActiveNav({ primaryNavItems, settingsNavItems }: IUseActiveNavProps) {
  const NAV_MAP = ref(generateNavKeys({ primaryNavItems, settingsNavItems }));
  const activeNavKey = ref<string | null>(null);

  const LEARN_PATHS = Object.freeze(['channels', 'dashboards']);

  const isLearnPath = (): boolean => {
    const currentPath = extractNavBaseUrl(window.location.pathname);

    return LEARN_PATHS.some((pathTag) => currentPath.includes(pathTag));
  };

  // Check if root path
  (() => {
    if (isLearnPath()) {
      activeNavKey.value = NavigationSection.MyLearning;
    }
  })();

  // Check for exact match
  (() => {
    if (activeNavKey.value) return;

    const currentPath = window.location.pathname;
    const match = NAV_MAP.value[currentPath];

    if (match) {
      activeNavKey.value = match;
    }
  })();

  // Check for base url match if no exact match
  (() => {
    if (activeNavKey.value) return;

    const currentPath = extractNavBaseUrl(window.location.pathname);
    const match = Object.keys(NAV_MAP.value).find((key) => extractNavBaseUrl(key) === currentPath);

    if (match) {
      activeNavKey.value = NAV_MAP.value[match];
    }
  })();

  const isNavActive = (navLink: PrimaryNavLink | SettingsMenu): boolean => {
    // When there are no url matches, then no nav will be highlighted
    if (!activeNavKey.value) return false;

    return activeNavKey.value === navLink.tag;
  };

  return { isNavActive };
}
